<template>

  <!-- 数据产品 -->
  <div class="app-metadata">

    <div class="metadata-tag-pannel">

      <template v-for="(group, gid) in tags">

        <div v-show="group.key === 'vars' || expanded"
          class="metadata-tag-group"
          :key="gid">

          <ul class="metadata-tag-list">

            <span class="metadata-tag-label">{{ group.label }}:
            </span>

            <li
              v-for="(item, index) in group.options"
              :class="['metadata-tag', activeTag(gid, item.value)]"
              :key="index"
              @click="toggleTag(gid, item.value)">{{ item.label }}
            </li>

          </ul>

        </div>

      </template>

      <div
        class="metadata-tag-expand"
        @click="toggleExpand()">

        <span v-show="!expanded">More options&nbsp;<i class="el-icon-arrow-down"></i>
        </span>

        <span v-show="expanded">Put it away&nbsp;<i class="el-icon-arrow-up"></i>
        </span>

      </div>

    </div>

    <el-form
      class="metadata-filter"
      inline
      size="small">

      <el-form-item label="Keywords">

        <el-input v-model="form.keyword"
          type="text">
        </el-input>

      </el-form-item>

      <el-form-item label="Date">

        <el-date-picker v-model="form.timerange"
          type="daterange"
          value-format="yyyy-MM-dd">
        </el-date-picker>

      </el-form-item>

      <el-form-item>

        <el-button @click="changePage(1)">Search
        </el-button>

        <el-button>Reset
        </el-button>

      </el-form-item>

    </el-form>

    <div class="metadata-toolbar">

      <span class="metadata-sort">

        <span
          :class="['metadata-sort-by', activeSortBy('')]"
          @click="toggleSortBy('')">Sort by
        </span>

        <span
          :class="['metadata-sort-by', activeSortBy('browsing')]"
          @click="toggleSortBy('browsing')">Access amount
        </span>

        <span
          :class="['metadata-sort-by', activeSortBy('updateTime')]"
          @click="toggleSortBy('updateTime')">Latest update time
        </span>

      </span>

      <span class="metadata-count">

        <!-- <span>共
        </span> -->

        <span class="metadata-total">{{ pager.total }}
        </span>

        <span>search results
        </span>

      </span>

    </div>

    <ul class="metadata-list">

      <li v-for="(item, index) in list"
        class="metadata-item"
        :key="index"
        :data-name="item.name">

        <a
          class="metadata-link"
          :href="item.alink"
          target="_blank">

          <img
            class="metadata-poster"
            :alt="item.name"
            :src="item.poster">

          <span class="metadata-title">{{ item.name }}
          </span>

          <div class="metadata-brief">{{ item.brief }}
          </div>

        </a>

        <!-- <div class="metadata-controls">

          <span
            class="subscribe-btn"
            @click="handleEvent('collect', item)"><i class="el-icon-bell"></i>&nbsp;订阅数据集
          </span>

        </div> -->

      </li>

    </ul>

    <el-pagination
      class="pager"
      :current-page="pager.current"
      :page-sizes="pager.sizes"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="changeSize"
      @current-change="changePage">
    </el-pagination>

  </div>

</template>

<script>
import * as api from '@/configs/api'

export default {
  name: 'app-metadata',
  data () {
    return {
      expanded: false,
      form: {
        keyword: null,
        timerange: null
      },
      list: [],
      pager: {
        current: 1,
        size: 15,
        sizes: [10, 15, 20, 25, 50, 100],
        total: 8
      },
      sortBy: '',
      tags: [
      ]
    }
  },
  created () {
    this.loadTags()
  },
  mounted () {
    this.loadList()
  },
  methods: {
    /**
     * 标签激活样式
     * @param index 第几类
     * @param value
     * @return {String}
     */
    activeTag (index, value) {
      return this.tags[index].current === value ? 'active' : ''
    },
    // 排序方式激活
    activeSortBy (value) {
      return this.sortBy === value ? 'active' : ''
    },
    // 页码
    changePage (page) {
      this.pager.current = page
      this.loadList()
    },
    // 条数
    changeSize (size) {
      this.pager.size = size
      this.loadList()
    },
    handleEvent (name, data) {
    },
    // 标签查询
    loadTags () {
      const params = {
        dictType: '元数据检索条件英文'
      }

      this.axios.get(api.dictionary.type, { params })
        .then((_http) => {
          const code = _http.data.code
          if (code === 200) {
            const data = _http.data.data
            this.tags = data.map((item) => {
              const options = [
                {
                  label: 'all',
                  value: null
                }
              ]
              item.children.forEach((subitem) => {
                options.push({
                  label: subitem.dictName,
                  value: subitem.dictValue
                })
              })
              return {
                current: null,
                key: item.dictValue,
                label: item.dictName,
                options
              }
            })
          }
        })
    },
    // 数据加载
    loadList () {
      const tags = this.tags
        .filter((item) => item.current)
        .map((item) => {
          return {
            tagsCode: item.key,
            tagsValue: item.current
          }
        })

      const params = new FormData()
      params.append('tags', tags.length ? JSON.stringify(tags) : '')
      params.append('beginTime', this.form.timerange ? this.form.timerange[0] : '')
      params.append('endTime', this.form.timerange ? this.form.timerange[1] : '')
      params.append('keywords', this.form.keyword || '')
      params.append('offset', this.pager.current)
      params.append('limit', this.pager.size)
      params.append('isEn', 1)

      this.axios.post(api.metadata.page, params)
        .then((_http) => {
          const code = _http.data.code
          if (code === 200) {
            const data = _http.data.data.records
            this.list = data.map((item) => {
              let poster = '/img/test.jpg'
              if (item.imagesList.length) {
                poster = this.$baseURL + item.imagesList[0].filePath
              }
              // alink跳转详情展示，base64加密-暂时不加密this.$Base64.encode(item.id)
              return {
                alink: `/data/metadata-special-detail?id=${item.id}`,
                brief: item.dataBrief,
                id: item.id,
                name: item.enName,
                poster,
                subscribed: Boolean(item.favorite - 0)
              }
            })
            this.pager.total = _http.data.data.total
          }
        })
    },
    // 展开按钮
    toggleExpand () {
      this.expanded = !this.expanded
    },
    // 切换筛选项
    toggleSortBy (value) {
      this.sortBy = value
    },
    /**
     * 切换标签
     * @param index 第几类
     * @param value 选中value
     */
    toggleTag (index, value) {
      this.tags[index].current = value
    }
  }
}
</script>

<style lang="scss" scoped>
.app-metadata {
  background-color: white;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 1200px;

  ul {
    margin: {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    };
  }
}

.metadata-tag-pannel {
  border-color: rgba(220, 223, 230, 1);
  border-style: solid;
  border-width: 1px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
.metadata-tag-group {
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  position: relative;
}
.metadata-tag-group:first-child {
  border-top: none;
}
.metadata-tag-label {
  left: 0;
  line-height: 26px;
  margin-right: 5px;
}
.metadata-tag-list {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  width: 100%;
}
.metadata-tag {
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  list-style: none;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}
.metadata-tag.active {
  border-color: rgba(255, 102, 0, 1);
  cursor: text;
}
.metadata-tag-expand {
  background-color: white;
  border-color: rgba(220, 223, 230, 1);
  border-style: solid;
  border-top-color: white;
  border-width: 1px;
  bottom: -29px;
  cursor: pointer;
  left: 50%;
  line-height: 28px;
  position: absolute;
  text-align: center;
  transform: translateX(-50px);
  width: 120px;
}

.metadata-filter {
  margin-top: 50px;
  text-align: left;
}

.metadata-toolbar {
  background-color: rgba(245, 245, 245, 1);
  border-color: rgba(220, 223, 230, 1);
  border-style: solid;
  border-width: 1px;
  line-height: 42px;
  padding-right: 20px;
  position: relative;
  text-align: left;
}
.metadata-sort-by {
  cursor: pointer;
  display: inline-block;
  line-height: 42px;
  padding-left: 15px;
  padding-right: 15px;
}
.metadata-sort-by.active {
  background-color: white;
  color: rgba(255, 102, 0, 1);
  cursor: text;
}
.metadata-count {
  float: right;
}
.metadata-total {
  color: orangered;
  margin-left: 5px;
  margin-right: 5px;
}

.metadata-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-left: 0px;
  text-align: left;
}
.metadata-item {
  border-color: rgba(238, 238, 238, 1);
  border-style: solid;
  border-width: 1px;
  list-style: none;
  margin-right: 18px;
  margin-top: 40px;
  padding-bottom: 15px;
  padding-left: 0;
  position: relative;
}
.metadata-item:nth-child(5n) {
  margin-right: 0;
}
.metadata-item::after {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgb(20, 39, 115);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: black;
  content: attr(data-name);
  left: 0;
  line-height: 18px;
  max-width: 100%;
  opacity: 0;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  position: absolute;
  top: 0;
  transition-delay: 0s;
  transition-duration: 0s;
  text-overflow: unset;
  visibility: hidden;
  white-space: unset;
  z-index: 9;
}
.metadata-item:hover::after {
  opacity: 1;
  transition-delay: .5s;
  transition-duration: .5s;
  visibility: visible;
}
.metadata-subscription {
  color: rgba(255, 102, 0, 1);
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.metadata-link {
  display: block;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.metadata-poster {
  display: block;
  height: 130px;
  width: 195px;
}
.metadata-title {
  color: rgba(51, 51, 51, 1);
  display: block;
  line-height: 40px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 195px;
}
.metadata-brief {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 195px;
}
.metadata-controls {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
}
.subscribe-btn {
  color: rgba(255, 102, 0, 1);
}

.pager {
  margin-top: 15px;
}
</style>
